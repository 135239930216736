import React from "react";
import "./App.css";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import Header from "./components/Header";
import PaperContent from "./components/PaperContent";
import { RootState } from "./store";

const App = () => {
  const counter  = useSelector((state: RootState) => state.counter);

  return (
    <Box className="App">
      <Box>
        <Header />
        <PaperContent counter={counter}/>
      </Box>
    </Box>
  );
};

export default App;
