import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const HEADER_COLOR = "#003B46";
const StyledAppbar = styled(AppBar)(() => ({
  backgroundColor: HEADER_COLOR,
}));
const StyledTypography = styled(Typography)(() => ({
  fontWeight: "bold",
  fontSize: "24px",
  margin: "0"
}));

const Header = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <StyledAppbar position="static" sx={{ padding: "20px" }}>
        <Toolbar variant="dense" sx={{ justifyContent: "center" }}>
          <StyledTypography variant="h1">
            文字数カウント
          </StyledTypography>
        </Toolbar>
      </StyledAppbar>
    </Box>
  );
};

export default Header;