import * as React from "react";
import { Box, Paper } from "@mui/material";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { resetCharacter, updateCharacter } from "../features/counter/CounterSlice";
import TextareaAutosize from "react-textarea-autosize";
import { makeStyles } from "@mui/styles";
import OutputField from "./OutputField";
import { OutputProps, PaperContentProps } from "../types";

const useStyles = makeStyles(() => ({
  textarea: {
    width: "80%",
    padding: "10px",
    margin: "40px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    resize: "vertical",
    backgroundColor: "#F8F8F8",
    color: "#333",
  }
}));

const PaperContent = (props: PaperContentProps) => {
  const { targetText, textAnalysisResult } = props.counter;
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    dispatch(updateCharacter(value));
  };
  const handleReset = () => {
    dispatch(resetCharacter());
  };
  const copyToClipboard = () => {
    targetText && navigator.clipboard.writeText(targetText);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& > :not(style)": {
          m: 1,
          minWidth: 700,
          minHeight: 700,
        },
      }}
    >
      <Paper elevation={4} sx={{
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
        <TextareaAutosize
          id={"based-input"}
          className={classes.textarea}
          minRows={6}
          maxLength={6000}
          value={targetText}
          placeholder="チェックしたい正規表現を入力してください"
          onChange={handleChange}
        />
        {textAnalysisResult.map((item: OutputProps, i: number) => (
          <OutputField key={i} label={item.label} result={item.result} description={item.description} />
        ))}
        <Box
          display="flex"
          justifyContent="space-between"
          p={1}
          m={1}
          bgcolor="background.paper"
          sx={{marginTop: 4}}
        >
          <Button variant="contained" color="error" sx={{marginRight: 8}} onClick={handleReset}>
            リセット
          </Button>

          <Button variant="contained" color="primary" onClick={copyToClipboard}>
            コピー
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default PaperContent;