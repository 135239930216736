// storeから値を取得する
// import {useSelector} from "react-redux";
// import {RootState} from "../store";


const calculateCharacterWithoutSpace = (text: string) => {
  let count = 0;

  for (let i = 0; i < text.length; i++) {
    const char = text.charAt(i);
    if (char.trim() !== "") {
      count += 1;
    }
  }
  return count;
};
const calculateCharacterLengthWithSpace = (text: string) => {
  return [...text].reduce((length, char) => {
    const charCode = char.charCodeAt(0);
    return length + (charCode >= 0 && charCode <= 255 ? 0.5 : 1);
  }, 0);
};
const calculateCharacterLengthWithoutSpace = (text: string) => {
  return [...text].reduce((length, char) => {
    if (char.trim() === "") {
      return length;
    }
    const charCode = char.charCodeAt(0);
    return length + (charCode >= 0 && charCode <= 255 ? 0.5 : 1);
  }, 0);
};

export const textAnalysis = (text: string) => {
  return [
    text.length,
    calculateCharacterWithoutSpace(text),
    calculateCharacterLengthWithSpace(text),
    calculateCharacterLengthWithoutSpace(text),
    text.length ? text.split(" ").length : 0,
    text.length ? text.split("\n").length : 0,
  ];
};
