import {createSlice} from "@reduxjs/toolkit";
import { textAnalysis } from "../../helper/textAnalysis";

const initialState = {
  targetText: "",
  textAnalysisResult: [
    { label: "文字数(スペースあり)", result: 0, description: "全ての文字が1文字として表示されます。(スペース・改行も含む)" },
    { label: "文字数(スペースなし)", result: 0, description: "スペース・改行は、カウント対象外として表示されます。" },
    { label: "文字数(半角・スペース0.5文字)", result: 0, description: "半角の文字・スペースを0.5文字として表示されます。" },
    { label: "文字数(半角0.5文字・スペースなし)", result: 0, description: "半角の文字を0.5文字・スペースは、カウント対象外として表示されます。" },
    { label: "単語数", result: 0, description: "英語などの単語の数が表示されます。" },
    { label: "行数", result: 0, description: "改行をした回数が表示されます。" },
  ]
};

const regexSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    updateCharacter(state, action) {
      const text = action.payload;
      state.targetText = text;
      const results = textAnalysis(text);
      results.forEach((result, i) => {
        state.textAnalysisResult[i].result = result;
      });
    },
    resetCharacter(state) {
      state.targetText = "";
      state.textAnalysisResult.forEach(item => {
        item.result = 0;
      });
    },
  },
});

export const { updateCharacter, resetCharacter } = regexSlice.actions;
export default regexSlice.reducer;