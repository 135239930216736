import * as React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { OutputProps } from "../types";

const OutputFiled: React.FC<OutputProps> = ({label, result, description}) => {
  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2} sx={{margin: "0 8px"}}>
      <Grid item>
        <Grid container justifyContent="left" alignItems="center" sx={{width: "320px"}}>
          <InputLabel htmlFor="my-input" sx={{ fontWeight: "bold"}}>{label}：</InputLabel>
          <Tooltip title={description} placement="right">
            <HelpOutlineIcon sx={{color: "#666666"}}/>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container>
          <FormControl fullWidth>
            <TextField
              id="my-input"
              sx={{ backgroundColor: "#F8F8F8" }}
              InputProps={{
                readOnly: true,
                style: { cursor: "not-allowed", backgroundColor: "#f8f8f8" },
              }}
              aria-describedby="my-helper-text"
              value={result}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OutputFiled;